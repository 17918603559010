<template>
    <div
        :id="title"
        class="bg-light min-h-827px w-full px-120px py-80px flex flex-col ipad:(px-24px py-48px min-h-1050px items-center) mobile:(px-16px py-32px items-center min-h-1719px)"
    >
        <div class="section_headline mobile:(leading-48px text-base-40px text-center)">
            <span class="ligne">{{ title }}</span>
        </div>
        <div
            class="mt-40px flex gap-24px flex-wrap justify-center ipad:(gap-y-24px gap-x-24px w-full) mobile:(gap-y-20px w-full mt-24px)"
        >
            <div
                v-for="(feature, i) in features"
                :key="i"
                class="flex gap-24px items-center min-h-168px w-588px py-31px px-24px border-solid border-grey border-1px rounded-10px ipad:(flex-col w-347px p-24px) mobile:(flex-col p-16px w-full min-h-231px)"
            >
                <icon-svg class="w-120px" :name="feature.icon" />
                <div>
                    <div class="title mt-0 tracking-0.3px ipad:(text-center) mobile:(text-center)">
                        {{ feature.title }}
                    </div>
                    <div
                        class="sf_18 tracking-0.3px text-16px leading-24px mr-10px ipad:(text-center mr-0) mobile:(text-center mr-0)"
                    >
                        {{ feature.desc }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'features',
    props: {
        features: {
            type: Array,
            default: () => []
        },
        title: String
    },
    data() {
        return {}
    }
}
</script>
<style></style>
